import { Component, Input, OnInit, inject } from "@angular/core";
import { LanguageService } from "src/app/core/services/language.service";
import { HomePageModule } from "src/app/models/home-page-module.model";
import { ProductCompact } from "src/app/models/shared-models/product.model";
import { CollectionService } from "src/app/services/collection.service";

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { SeriesCompactComponent } from "src/app/components/shop/series-compact/series-compact.component";
import { SliderComponent } from "src/app/components/slider/slider.component";

enum LoadStates {
  LOADING,
  LOADED,
  ERROR,
}

@Component({
  selector: "app-homepage-series",
  templateUrl: "./homepage-series.component.html",
  styleUrls: ["./homepage-series.component.scss"],
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    NgxGoogleAnalyticsModule,
    SeriesCompactComponent,
    SliderComponent,
  ],
})
export class HomepageSeriesComponent implements OnInit {
  private collectionService = inject(CollectionService);
  lang = inject(LanguageService);

  series: ProductCompact[] = [];
  state: LoadStates = LoadStates.LOADING;
  @Input() module!: HomePageModule;

  SeriesStates = LoadStates;

  breakpoints: any = {
    "(min-width: 576px)": {
      slides: { perView: 2 },
    },
    "(min-width: 992px)": {
      slides: { perView: 3 },
    },
  };

  constructor() {}

  ngOnInit(): void {
    // the data field contains the collection name
    const collectionName = this.module.data;
    this.loadCollection(collectionName);
  }

  async loadCollection(collectionName: string) {
    this.state = LoadStates.LOADING;

    try {
      const collection = await this.collectionService.loadCollection(
        collectionName
      );
      this.series = collection.products;
      this.state = LoadStates.LOADED;
    } catch (error) {
      this.state = LoadStates.ERROR;
      this.series = [];
      return;
    }
  }
}
