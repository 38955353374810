@if (series.length > 0) {
  <section id="homepage-series">
    <div class="container" gaCategory="hp_series">
      <h1 class="title max-width">{{ "SERIES.OurSeries" | translate }}</h1>
      <div id="series-slider" class="max-width md-up">
        @for (item of series; track item) {
          <app-series-compact
            gaEventPrefix="hp_"
            [product]="item"
          ></app-series-compact>
        }
      </div>
      <div id="series-slider-2" class="md-down">
        <slider
          [slides]="series"
          [slidesPerView]="1"
          [breakpoints]="breakpoints"
          [showDots]="false"
          [loop]="false"
          [fullHeight]="false"
        >
        </slider>
      </div>
      <button
        gaEvent="hp_series_all_series"
        class="large"
        [routerLink]="['/', lang.currentLanguage(), 'search']"
        [queryParams]="{ tags: 'series' }"
      >
        {{ "SERIES.DiscoverAll" | translate }}
      </button>
    </div>
  </section>
}
